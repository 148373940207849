<template>
  <div style="position: relative">
    <div class="common-gaine">
      <div class="common-plate-title">
        <span>{{ $trans('开具发票', '开具发票') }}</span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div class="order-info-container">
        <div class="open-ticket-item flex-bet-cen font-medium">
          <div class="open-ticket-tip">
            {{ $trans('发票类型', '发票类型') }}
          </div>
          <div class="open-ticket-value">
            {{ $trans('增值税电子普通发票', '增值税电子普通发票') }}
          </div>
        </div>
        <div class="open-ticket-item flex-bet-cen font-medium">
          <div class="icon-bitian iconfont open-ticket-tip">
            {{ $trans('抬头类型', '抬头类型') }}
          </div>
          <div class="open-ticket-value flex-cen">
            <label for="001999" class="ticket-select-item flex-cen">
              <input
                type="radio"
                value="0"
                v-model="formData.riseCate"
                name="riseCate"
                id="001999"
              />
              <span>{{ $trans('个人或事业单位', '个人或事业单位') }}</span>
            </label>
            <label for="002999" class="ticket-select-item flex-cen">
              <input
                type="radio"
                value="1"
                v-model="formData.riseCate"
                name="riseCate"
                id="002999"
              />
              <span>{{ $trans('企业', '企业') }}</span>
            </label>
          </div>
        </div>
        <div class="open-ticket-item flex-bet-cen font-medium">
          <div class="open-ticket-tip icon-bitian iconfont">
            {{ $trans('发票抬头', '发票抬头') }}
          </div>
          <input
            type="text"
            v-model="formData.invoiceTitle"
            :placeholder="
              $trans('填写需要开具发票的企业名称', '填写需要开具发票的企业名称')
            "
          />
        </div>
        <div
          class="open-ticket-item flex-bet-cen font-medium"
          v-show="formData.riseCate != '0'"
        >
          <div class="open-ticket-tip icon-bitian iconfont">
            {{ $trans('税号', '税号') }}
          </div>
          <input
            type="text"
            v-model="formData.invoiceTaxNo"
            :placeholder="$trans('纳税人识别号', '纳税人识别号')"
          />
        </div>
        <div class="open-ticket-item flex-bet-cen font-medium">
          <div class="open-ticket-tip icon-bitian iconfont">
            {{ $trans('接收邮箱', '接收邮箱') }}
          </div>
          <input
            type="text"
            v-model="formData.receiveEmail"
            :placeholder="
              $trans(
                '请输入您的邮箱,稍后我们会将发票发送至您的邮箱',
                '请输入您的邮箱,稍后我们会将发票发送至您的邮箱'
              )
            "
          />
        </div>
        <div class="open-ticket-item flex-bet-cen font-medium">
          <div class="open-ticket-tip">
            {{ $trans('注册地址', '注册地址') }}
          </div>
          <input
            type="text"
            v-model="formData.registerAddress"
            :placeholder="$trans('请输入注册地址', '请输入注册地址')"
          />
        </div>
        <!-- <div class="open-ticket-item flex-bet-cen font-medium">
                    <div class="open-ticket-tip">接收电话</div>
                    <input type="text" v-model="formData.receivePhone" placeholder="请输入注册电话" />
                </div> -->
        <div class="open-ticket-item flex-bet-cen font-medium">
          <div class="open-ticket-tip">{{ $trans('开户行', '开户行') }}</div>
          <input
            type="text"
            v-model="formData.bankBranch"
            :placeholder="$trans('请输入开户行', '请输入开户行')"
          />
        </div>
        <div class="open-ticket-item flex-bet-cen font-medium">
          <div class="open-ticket-tip">
            {{ $trans('开户账号', '开户账号') }}
          </div>
          <input
            type="text"
            v-model="formData.bankAccount"
            :placeholder="$trans('请输入开户账号', '请输入开户账号')"
          />
        </div>
        <div class="btn-fill btn" @click="submitHandle">
          {{ $trans('确定', '确定') }}
        </div>
        <div class="btn-empty btn" @click="cancelHandle">
          {{ $trans('不开发票', '不开发票') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      // riseCate:"",
      defaultSelect: false,
      formData: {
        riseCate: '', // 抬头类型
        bankAccount: '', // 开户账号
        bankBranch: '', // 开户行
        // receivePhone:"",    // 接收电话
        registerAddress: '', // 注册地址
        receiveEmail: '', // 接收邮箱
        invoiceTaxNo: '', // 税号
        invoiceTitle: '', // 发票抬头
      },
    }
  },
  methods: {
    submitHandle() {
      let emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      // let accountReg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/;
      let invoiceTaxNoReg = /^[A-Z0-9]{15}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
      if (this.formData.riseCate && this.formData.riseCate == '0') {
        if (this.formData.receiveEmail && this.formData.invoiceTitle) {
          if (!emailReg.test(this.formData.receiveEmail)) {
            this.$message.warning(
              this.$trans('请将信息规范填写', '请将信息规范填写')
            )
            return
          }
        } else {
          this.$message.warning(this.$trans('请填写完整信息', '请填写完整信息'))
          return
        }
      } else if (this.formData.riseCate && this.formData.riseCate == '1') {
        if (
          this.formData.receiveEmail &&
          this.formData.invoiceTitle &&
          this.formData.invoiceTaxNo
        ) {
          if (
            !(
              emailReg.test(this.formData.receiveEmail) &&
              invoiceTaxNoReg.test(this.formData.invoiceTaxNo)
            )
          ) {
            this.$message.warning(
              this.$trans('请将信息规范填写', '请将信息规范填写')
            )
            return
          }
        } else {
          this.$message.warning(this.$trans('请填写完整信息', '请填写完整信息'))
          return
        }
      } else {
        this.$message.warning(this.$trans('请填写完整信息', '请填写完整信息'))
        return
      }
      this.$router.history.push({
        path: '/order/new',
        query: {
          a: '已开具发票',
          ...this.$route.query,
          ticket: JSON.stringify({
            userId: this.$store.state.userId,
            bankAccount: this.formData.bankAccount,
            depositeBank: this.formData.bankBranch,
            receiveEmail: this.formData.receiveEmail,
            registerAddress: this.formData.registerAddress,
            type: 88,
            invoiceTitle: this.formData.invoiceTitle,
            invoiceTaxNo: this.formData.invoiceTaxNo
              ? this.formData.invoiceTaxNo
              : '',
          }),
        },
      })
    },
    cancelHandle() {
      this.$router.history.push({
        path: '/order/new',
        query: {
          a: '本次不开具发票',
          ...this.$route.query,
        },
      })
    },
  },
}
</script>
<style scoped>
.order-info-container {
  padding: 30px;
  border: 1px solid #dfdfdf;
}
.open-ticket-tip {
  font-size: 16px;
}
.open-ticket-item {
  margin-bottom: 40px;
}
.ticket-select-item {
  margin-right: 20px;
  cursor: pointer;
}
.ticket-select-item:last-child {
  margin-right: 0;
}
.ticket-select-item input[type='radio'] {
  margin-right: 10px;
}
.open-ticket-item input[type='text'] {
  border: none;
  outline: none;
  text-align: right;
  flex: 1;
  margin-left: 20px;
}
.btn {
  width: 400px;
  margin: 0 auto;
  cursor: pointer;
}
.btn-fill {
  margin-top: 140px;
}
.btn-empty {
  margin-top: 20px;
}

.switch {
  /* 定义position为relative，子元素的absolute在这里作参照 */
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.switch input {
  /* 隐藏input的显示 */
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  /* 撑满外层的div标签 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  /* 设置显示时候的动画 */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
/* 设置子div前面的元素 */

.slider:before {
  position: absolute;
  /* before的内容为空白 */
  content: '';
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  /* 设置按钮点击的时候的动画效果 */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
/* 两个属性同时存在的时候的css样式 */

input:checked + .slider {
  background-color: teal;
}

input:focus + .slider {
  box-shadow: 0 0 1px teal;
}
/* 设置before元素的平移动画 */

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}
/* 设置下面一组按钮的圆角 */

.slider.round {
  border-radius: 8px;
}

.slider.round:before {
  border-radius: 50%;
}
.icon-bitian::before {
  color: red;
}
</style>
